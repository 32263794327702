import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { MainPage } from 'pages/mainPage/MainPage';

import 'index.module.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
    },
    {
        path: '/example1',
        element: <MainPage />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
