import React, { useState } from 'react';
import styles from 'sections/works/works.module.css';
import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { ArrowDownSmall } from 'components/vectors/ArrowDownSmall';
import { WorkListLazy } from 'sections/works/WorksListLazy';
import {
    useGetOtherWorksList,
    useGetWorksList,
} from 'sections/works/hooks/useGetWorksList.hooks';

const Works = () => {
    const workList = useGetWorksList();
    const otherWorkList = useGetOtherWorksList();

    const [isOtherWorksShown, setIsOtherWorksShown] = useState(false);
    const { t } = useTranslation();
    const showHideOtherWorks = () => {
        setIsOtherWorksShown(!isOtherWorksShown);
    };

    const buttonText = isOtherWorksShown
        ? t('works.hideMoreWorksBtnText')
        : t('works.showMoreWorksBtnText');

    const buttonIconStyle = isOtherWorksShown
        ? `${styles.works__otherIcon_hide}`
        : `${styles.works__otherIcon}`;

    return (
        <section id="section-works" className={styles.works__section}>
            <Element name="works">
                <h2 className={styles.worksTitle}>{t('works.title')}</h2>

                <WorkListLazy workList={workList} />

                {isOtherWorksShown && <WorkListLazy workList={otherWorkList} />}

                <button
                    className={styles.works__otherBtn}
                    onClick={showHideOtherWorks}
                >
                    <p>{buttonText}</p>
                    <div className={buttonIconStyle}>
                        <ArrowDownSmall />
                    </div>
                </button>
            </Element>
        </section>
    );
};

export default Works;
