type TGetImageUrl = (_src: string | undefined) => string | undefined;
export const getDesktopSrc2x: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('%401x', '%402x');
};

export const getDesktopSrc3x: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('%401x', '%403x');
};

export const getDesktopSrcWebp: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('png', 'webp');
};

export const getDesktopSrcWebp2x: TGetImageUrl = desktopSrcWebp => {
    return desktopSrcWebp?.replace('%401x', '%402x');
};

export const getDesktopSrcWebp3x: TGetImageUrl = desktopSrcWebp => {
    return desktopSrcWebp?.replace('%401x', '%403x');
};

export const getMobileSrc: TGetImageUrl = imageUrl => {
    return imageUrl?.replace('424x275', '320x220');
};

export const getMobileSrc2x: TGetImageUrl = mobileSrc => {
    return mobileSrc?.replace('%401x', '%402x');
};

export const getMobileSrc3x: TGetImageUrl = mobileSrc => {
    return mobileSrc?.replace('%401x', '%403x');
};

export const getMobileSrcWebp: TGetImageUrl = mobileSrc => {
    return mobileSrc?.replace('png', 'webp');
};

export const getMobileSrcWebp2x: TGetImageUrl = mobileSrcWebp => {
    return mobileSrcWebp?.replace('%401x', '%402x');
};

export const getMobileSrcWebp3x: TGetImageUrl = mobileSrcWebp => {
    return mobileSrcWebp?.replace('%401x', '%403x');
};
