import { useEffect } from 'react';
import { getScrollPosition, setScrollPosition } from 'utils/localStorage.utils';

export const useScrollPosition = () => {
    setScrollPosition();

    useEffect(() => {
        const savedPosition = getScrollPosition();

        if (savedPosition) {
            setTimeout(() => {
                window.scrollTo(0, parseInt(savedPosition));
            }, 30);
        }
    }, []);
};
