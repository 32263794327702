import styles from 'components/footer/footer.module.css';
import { LogoWhite } from 'components/vectors/LogoWhite';
import { ContactUsTelegramIcon } from 'components/vectors/ContactUsTelegramIcon';
import { ContactUsPhoneIcon } from 'components/vectors/ContactUsPhoneIcon';
import { IS_MOBILE } from 'constants/responsive';
import { PhoneNumbers } from 'components/footer/PhoneNumbers';
import { ContactUsWhatsAppIcon } from '../vectors/ContactUsWhatsAppIcon';

export const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.footer__logo}>
                <LogoWhite />
            </div>
            <div className={styles.footer__contactInfo}>
                {IS_MOBILE && <PhoneNumbers />}
                <div className={styles.footer__contactIcons}>
                    <a
                        href="tel:+971 (50) 370 57 30"
                        className={styles.footer__contactUsIcon}
                    >
                        <ContactUsPhoneIcon />
                    </a>
                    <a
                        href="https://t.me/velvetyweb"
                        className={styles.footer__contactUsIcon}
                    >
                        <ContactUsTelegramIcon />
                    </a>
                    <a
                        href="https://wa.me/+971503705730"
                        className={styles.footer__contactUsIcon}
                    >
                        <ContactUsWhatsAppIcon />
                    </a>
                </div>
                {!IS_MOBILE && <PhoneNumbers />}
            </div>
            <p>all right reserved</p>
        </div>
    );
};
