import React from 'react';
import styles from 'components/animatedPart/animatedPart.module.css';
import { useIntersectionObserver } from 'hooks/useInterspectionObserver';

interface AnimatedPartProps {
    children: React.ReactNode;
    delay?: string;
    className?: string;
}

export const AnimatedPart: React.FC<AnimatedPartProps> = ({
    children,
    delay,
    className,
}) => {
    const { ref, isVisible } = useIntersectionObserver();

    const transitionDelay = delay || 'none';

    return (
        <div
            ref={ref}
            className={`${styles.hidden} ${isVisible ? styles.visible : ''} ${className}`}
            style={{
                transitionDelay,
            }}
        >
            {children}
        </div>
    );
};
