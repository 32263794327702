import React from 'react';

export const LogoWhite = () => {
    return (
        <svg
            width="202"
            height="38"
            viewBox="0 0 202 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="logoWhitT">logo white</title>
            <desc id="logoWhitD">A vector illustration of a logo white</desc>
            <g clipPath="url(#a)" fill="#fff">
                <path d="M16.035 37.03c-1.604 0-2.69-.738-3.257-2.216L0 1.511c.334-.168.785-.32 1.353-.454A6.63 6.63 0 0 1 2.856.856c2.64 0 4.343 1.075 5.111 3.224l7.717 21.715-1.203 3.225.552.202 9.02-25.142c.8-2.15 2.482-2.57 5.088-2.57.435 0 .96-.133 1.528 0 .568.135 1.033-.167 1.4 0L19.292 34.815c-.267.705-.685 1.26-1.253 1.663-.534.37-1.203.554-2.004.554Z" />
                <path d="M23.954 4.383c0-.84.317-1.562.952-2.167.668-.604 1.47-.907 2.405-.907H49.66c0 2.016-.351 3.494-1.052 4.434-.669.907-1.771 1.36-3.308 1.36H30.67v8.767h13.329c0 2.015-.351 3.493-1.053 4.434-.668.907-1.77 1.36-3.307 1.36h-8.97v9.12h15.685c1.503 0 11.585-.013 11.976.38.702.907 1.566 3.398 1.566 5.413H27.311c-.935 0-1.737-.285-2.405-.856a2.944 2.944 0 0 1-.952-2.217V4.383Z" />
                <path d="M59.024 36.578c-.968 0-1.804-.353-2.505-1.059A3.537 3.537 0 0 1 55.517 33V5.844c0-1.68.55-2.922 1.653-3.728 1.103-.84 2.79-1.26 5.061-1.26v29.877h13.53c1.57 0 2.756.504 3.558 1.512.801.974 1.202 2.418 1.202 4.333H59.024Z" />
                <path d="M81.552 36.675c-1.604 0-2.69-.739-3.258-2.217L66.55 1.155c.334-.168.785-.32 1.353-.453A6.63 6.63 0 0 1 69.406.5c2.64 0 3.31 1.075 4.078 3.225l7.717 21.715-1.203 3.224.551.202L90.602 3.725c.802-2.15 2.312-2.476 4.917-2.476.435 0 .998-.003 1.566.131.568.135 1.206.226 1.566 0L84.81 34.458c-.267.706-.685 1.26-1.253 1.663-.535.37-1.203.554-2.004.554Z" />
                <path d="M93.17 4.323c0-.84.317-1.562.953-2.167.667-.604 1.47-.907 2.405-.907h22.348c0 2.016-.351 3.494-1.052 4.434-.668.907-1.77 1.36-3.307 1.36H99.885v8.767h13.329c0 2.015-.351 3.493-1.052 4.434-.668.907-1.771 1.36-3.308 1.36h-8.97v9.12h15.685c1.503 0 2.589.47 3.257 1.41.701.907 1.052 2.368 1.052 4.383h-23.35c-.936 0-1.738-.285-2.406-.856a2.943 2.943 0 0 1-.952-2.217V4.323Z" />
                <path d="M128.012 7.094h-12.076c0-3.896-.567-5.845 2.94-5.845h27.927c0 3.897-1.771 5.845-5.312 5.845h-6.765v24.889c0 1.612-.567 2.855-1.703 3.728-1.136.84-2.806 1.26-5.011 1.26V7.094Z" />
                <path d="m155.656 23.931-12.377-22.37c.301-.201.898-.177 1.566-.312h1.958c1.236 0 2.005-.141 2.84.363s1.537 1.293 2.105 2.368l7.115 13.452-1.353 2.872.501.201 8.268-16.525c1.035-2.083 2.773-3.124 5.211-3.124.534 0 1.136.067 1.804.201.668.135 1.153.303 1.453.504l-12.376 22.42v8.062c0 1.612-.585 2.855-1.754 3.728-1.136.84-2.79 1.26-4.961 1.26v-13.1ZM178.292 37.031c-.418 0-.764-.092-1.04-.277a1.417 1.417 0 0 1-.539-.819l-1.966-7.759c.2-.126.459-.189.776-.189.31 0 .564.076.764.227.201.143.335.349.401.617l1.053 4.774-.251.97.113.038 1.703-6.059a.797.797 0 0 1 .264-.415.766.766 0 0 1 .476-.152c.184 0 .342.05.476.152.133.1.225.239.275.415l1.366 5.164-.238 1.02.137.038 1.353-5.945c.059-.268.184-.474.376-.617.201-.151.46-.227.777-.227.309 0 .572.063.789.19l-1.979 7.758a1.392 1.392 0 0 1-.551.819c-.267.185-.61.277-1.028.277l-1.904-7.003-.113.038.201.743-1.691 6.222ZM186.558 28.869c0-.21.08-.39.238-.542a.868.868 0 0 1 .601-.226h5.588c0 .504-.088.873-.264 1.108-.166.227-.442.34-.826.34h-3.658v2.192h3.332c0 .504-.088.873-.263 1.108-.167.227-.442.34-.827.34h-2.242v2.28h3.921c.376 0 .647.118.814.353.175.227.263.592.263 1.096h-5.838a.898.898 0 0 1-.601-.214.738.738 0 0 1-.238-.555v-7.28ZM195.289 36.918a.848.848 0 0 1-.589-.227.703.703 0 0 1-.251-.542v-7.28c0-.21.079-.39.238-.542a.865.865 0 0 1 .602-.226h3.369c.802 0 1.441.226 1.917.68.484.445.727 1.045.727 1.801 0 .361-.097.705-.289 1.033-.192.319-.447.567-.764.743v.038c.434.151.777.399 1.027.743.259.344.389.739.389 1.184 0 .504-.118.953-.351 1.348-.234.394-.56.7-.977.92a3.039 3.039 0 0 1-1.428.327h-3.62Zm3.344-5.114a.889.889 0 0 0 .702-.34c.2-.227.3-.5.3-.819 0-.344-.092-.617-.275-.819-.176-.201-.418-.302-.727-.302h-2.555v2.28h2.555Zm.251 3.69c.342 0 .614-.1.814-.302.201-.21.301-.495.301-.856 0-.353-.1-.626-.301-.82-.193-.2-.463-.301-.814-.301h-2.806v2.28h2.806Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill="#fff"
                        transform="translate(0 .5)"
                        d="M0 0h202v37H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
