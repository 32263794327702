import { Link } from 'react-scroll';
import styles from 'components/menu/menu.module.css';
import { useTranslation } from 'react-i18next';

export const Menu = ({
    isMobile = false,
    toggleMenu,
}: {
    isMobile?: boolean;
    toggleMenu?: () => void;
}) => {
    const { t } = useTranslation();

    const menuItems = [
        { id: 1, title: t('menu.Home'), link: 'home' },
        { id: 2, title: t('menu.About'), link: 'about' },
        { id: 3, title: t('menu.Offers'), link: 'offers' },
        { id: 4, title: t('menu.Works'), link: 'works' },
        { id: 5, title: t('menu.Contact'), link: 'contact' },
    ];

    const listStyles = isMobile
        ? `${styles.menuList} ${styles.menuList__column}`
        : `${styles.menuList}`;

    const headerHeightMobile = 75;
    const headerHeightDesktop = 87;
    const scrollOffsetHeader = isMobile
        ? -headerHeightMobile
        : -headerHeightDesktop;

    return (
        <nav className={styles.menu}>
            <ul className={listStyles}>
                {menuItems.map(({ id, link, title }) => (
                    <li key={id} className={styles.menuItem}>
                        <Link
                            to={link}
                            onClick={toggleMenu}
                            className={styles.menuLink}
                            activeClass={styles.active}
                            smooth={true}
                            duration={800}
                            spy={true}
                            offset={scrollOffsetHeader}
                            saveHashHistory={true}
                        >
                            {title}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};
