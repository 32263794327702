import { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { IWorkList } from 'sections/works/WorksList';

const WorkListLazyLoaded = lazy(() => import('./WorksList'));

export const WorkListLazy = ({ workList }: IWorkList) => {
    const { t } = useTranslation();

    return (
        <Suspense fallback={t('works.suspenseLoading')}>
            <WorkListLazyLoaded workList={workList} />
        </Suspense>
    );
};
