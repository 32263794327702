import styles from 'sections/hero/hero.module.css';
import generalStyles from 'index.module.css';

import { Element } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import { ArrowDown } from 'components/arrowDown/ArrowDown';
import { RunningTextCompositionLazy } from 'components/runningText/RunningTextCompositionLazy';
import LaptopImgBox from 'components/laptopImgBox/LaptopImgBox';
import { AnimatedPart } from 'components/animatedPart/AnimatedPart';

export const Hero = () => {
    const { t } = useTranslation();

    return (
        <section id="section-hero">
            <Element name="home" className={styles.hero}>
                <RunningTextCompositionLazy />
                <div className={generalStyles.container}>
                    <div className={styles.hero__content}>
                        <div className={styles.text}>
                            <AnimatedPart>
                                <h1
                                    className={`${styles.heading} animated-items`}
                                >
                                    {t('hero.creatingYourPerfectWebSpace')}
                                </h1>
                            </AnimatedPart>

                            <AnimatedPart delay="0.3s">
                                <span className={styles.subheading}>
                                    {t('hero.textSecondary')}
                                </span>
                            </AnimatedPart>
                        </div>
                        <ArrowDown />

                        <LaptopImgBox />
                    </div>
                </div>
            </Element>
        </section>
    );
};
