import React from 'react';

export const ArrowDownSmall = () => {
    return (
        <svg
            width="28"
            height="25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="smallArrowDownT">Small arrow down</title>
            <desc id="smallArrowDownD">
                A vector illustration of a small arrow down
            </desc>
            <g filter="url(#a)">
                <path
                    d="M5.269 7.82a1 1 0 0 0 0 1.415l6.644 6.644a3 3 0 0 0 4.242.001l6.576-6.57a1 1 0 1 0-1.415-1.415l-6.572 6.572a1 1 0 0 1-1.414 0L6.683 7.82a1 1 0 0 0-1.414 0Z"
                    fill="#fff"
                />
            </g>
            <defs>
                <filter
                    id="a"
                    x="-2"
                    y="0"
                    width="32"
                    height="32"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_612_5161"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend
                        in2="effect1_dropShadow_612_5161"
                        result="effect2_dropShadow_612_5161"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect2_dropShadow_612_5161"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
