import React, { useState } from 'react';
import styles from 'components/burgerMenu/burgerMenu.module.css';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Menu } from 'components/menu/Menu';
import { HireUsButtonHeader } from 'components/hireUsButtonHeader/hireUsButtonHeader';
import { AnimatedPart } from 'components/animatedPart/AnimatedPart';

export const BurgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const toggleMenu = () => setIsOpen(!isOpen);
    const burgerContainerStyles = isOpen
        ? `${styles.burgerContainerOpen}`
        : `${styles.burgerContainer}`;
    const burgerStyles = isOpen ? `${styles.arrowBack}` : `${styles.burger}`;

    return (
        <div className={styles.container}>
            <div className={burgerContainerStyles}>
                <div className={burgerStyles} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            {isOpen && (
                <div className={styles.fullScreenMenu}>
                    <span className={styles.menuTitle}>
                        <AnimatedPart delay="0.4s">
                            {t('menu.menuTitle')}
                        </AnimatedPart>
                    </span>

                    <AnimatedPart delay="0.6s">
                        <Menu isMobile={true} toggleMenu={toggleMenu} />
                    </AnimatedPart>

                    <AnimatedPart delay="0.8s">
                        <Link
                            to="contact"
                            smooth={true}
                            duration={800}
                            spy={true}
                            offset={-74}
                            onClick={toggleMenu}
                        >
                            <HireUsButtonHeader />
                        </Link>
                    </AnimatedPart>
                </div>
            )}
        </div>
    );
};
