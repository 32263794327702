import React from 'react';
import { Element } from 'react-scroll';
import styles from 'sections/offers/offers.module.css';
import { useTranslation } from 'react-i18next';
import { AnimatedPart } from 'components/animatedPart/AnimatedPart';

export const Offers = () => {
    const { t } = useTranslation();

    const offersData = [
        {
            id: 1,
            text: t('offers.websiteDesign'),
        },
        { id: 2, text: t('offers.mobileOptimization') },
        { id: 3, text: t('offers.ECommerceSolutions') },
        { id: 4, text: t('offers.websiteDevelopment') },
        { id: 5, text: t('offers.webHosting') },
        { id: 6, text: t('offers.maintenanceAndSupport') },
    ];

    return (
        <section id="section-offers" className={styles.offers__section}>
            <Element name="offers">
                <div className={styles.offers}>
                    <h2 className={styles.offersTitle}>{t('offers.title')}</h2>
                    <ul className={styles.offersList}>
                        {offersData.map(({ id, text }) => {
                            const delayIndex = 0.15;
                            const delay = id * delayIndex;
                            return (
                                <li key={id}>
                                    <AnimatedPart delay={`${delay}s`}>
                                        <div
                                            className={styles.offersList__item}
                                        >
                                            <p>{text}</p>
                                        </div>
                                    </AnimatedPart>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Element>
        </section>
    );
};
