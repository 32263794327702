import React from 'react';
import styles from 'components/laptopImgBox/laptopImgBox.module.css';
import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import {
    getDesktopSrc2x,
    getDesktopSrc3x,
    getDesktopSrcWebp,
    getDesktopSrcWebp2x,
    getDesktopSrcWebp3x,
    getMobileSrc2x,
    getMobileSrc3x,
    getMobileSrcWebp,
    getMobileSrcWebp2x,
    getMobileSrcWebp3x,
} from 'utils/imageResize.utils';

const LaptopImgBox = () => {
    const src =
        'https://velvety-general.s3.eu-north-1.amazonaws.com/laptop/laptop-683x721%401x.png';

    const desktopSrc2x = getDesktopSrc2x(src);
    const desktopSrc3x = getDesktopSrc3x(src);

    const desktopSrcWebp = getDesktopSrcWebp(src);
    const desktopSrcWebp2x = getDesktopSrcWebp2x(desktopSrcWebp);
    const desktopSrcWebp3x = getDesktopSrcWebp3x(desktopSrcWebp);

    const mobileSrc =
        'https://velvety-general.s3.eu-north-1.amazonaws.com/laptop/laptop-362x379%401x.png';

    const mobileSrc2x = getMobileSrc2x(mobileSrc);
    const mobileSrc3x = getMobileSrc3x(mobileSrc);

    const mobileSrcWebp = getMobileSrcWebp(mobileSrc);
    const mobileSrcWebp2x = getMobileSrcWebp2x(mobileSrcWebp);
    const mobileSrcWebp3x = getMobileSrcWebp3x(mobileSrcWebp);

    return (
        <div className={styles.mainPicBox}>
            <AnimatedPart delay="0.6s">
                <picture>
                    <source
                        type="image/webp"
                        media="(max-width: 600px)"
                        srcSet={`${mobileSrcWebp} 1x, ${mobileSrcWebp2x} 2x, ${mobileSrcWebp3x} 3x`}
                        sizes="(max-width: 600px) 100vw"
                    />
                    <source
                        type="image/png"
                        media="(max-width: 600px)"
                        srcSet={`${mobileSrc} 1x,  
                            ${mobileSrc2x} 2x, 
                            ${mobileSrc3x} 3x`}
                        sizes="(max-width: 600px) 100vw"
                    />

                    <source
                        type="image/webp"
                        srcSet={`${desktopSrcWebp} 1x, ${desktopSrcWebp2x} 2x, ${desktopSrcWebp3x} 3x`}
                        sizes="100vw"
                    />

                    <img
                        src={src}
                        srcSet={`${desktopSrc2x} 2x, ${desktopSrc3x} 3x`}
                        sizes="100vw"
                        alt="laptop"
                        width="100%"
                        height="100%"
                    />
                </picture>
            </AnimatedPart>
        </div>
    );
};
export default LaptopImgBox;
