import styles from 'components/arrowDown/arrowDown.module.css';
import { Link } from 'react-scroll';
import { ArrowDownIcon } from 'components/vectors/ArrowDownIcon';

export const ArrowDown = () => {
    return (
        <div className={styles.arrowDownBox}>
            <Link
                to="about"
                smooth={true}
                duration={800}
                spy={true}
                offset={-87}
            >
                <ArrowDownIcon />
            </Link>
        </div>
    );
};
