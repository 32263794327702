const scrollPositionKey = 'scrollPosition';
export const setScrollPosition = () => {
    window.addEventListener('beforeunload', () => {
        const scrollPosition =
            window.pageYOffset || document.documentElement.scrollTop;
        localStorage.setItem(scrollPositionKey, scrollPosition.toString());
    });
};

export const getScrollPosition = () => localStorage.getItem(scrollPositionKey);
