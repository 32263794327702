import React from 'react';
import { ISVGProps } from 'interfaces/svg';

export const ArrowDownIcon = ({
    width = '32px',
    height = '42px',
}: ISVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 32 42"
        role="img"
        fill="none"
        aria-labelledby="arrow down"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title id="arrowDownT">Arrow down</title>
        <desc id="arrowDownD">A vector illustration of a arrow down</desc>
        <path
            d="M16 0.999999L16 41M16 41L31 26M16 41L0.999999 26"
            stroke="#2562E8"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
