import React from 'react';

export const ContactUsPhoneIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="phoneWhiteIconT">Phone icon</title>
            <desc id="phoneWhiteIconD">
                A vector illustration of a phone icon
            </desc>
            <rect x=".5" y=".5" width="31" height="31" rx="3.5" stroke="#fff" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.687 18.349c.453.165 2.902 1.363 3.4 1.611l.266.13c.35.168.588.282.688.449.124.206.123 1.197-.29 2.354-.413 1.158-2.404 2.214-3.36 2.356-.857.128-1.942.18-3.134-.197a28.85 28.85 0 0 1-2.837-1.043C11.74 22 8.58 17.477 8 16.646l-.081-.115-.011-.014c-.284-.38-2.021-2.707-2.021-5.11 0-2.279 1.126-3.476 1.644-4.027l.098-.104a1.833 1.833 0 0 1 1.326-.62c.33 0 .665.004.955.018.036.002.073.001.111.001.29-.002.651-.004 1.009.849l.534 1.29c.432 1.048.915 2.22 1 2.387.124.248.206.537.04.867l-.068.14c-.125.254-.216.44-.429.687-.084.098-.17.203-.257.308-.17.206-.34.413-.49.56-.248.247-.507.515-.217 1.01.29.496 1.288 2.118 2.768 3.431 1.588 1.41 2.97 2.007 3.671 2.31.138.06.25.107.331.148.498.248.788.212 1.078-.123.29-.335 1.245-1.446 1.576-1.942.331-.496.666-.413 1.12-.248Z"
                fill="#fff"
            />
        </svg>
    );
};
