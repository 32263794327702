import styles from 'components/hireUsButtonHeader/hireUsButtonHeader.module.css';
import { useTranslation } from 'react-i18next';

export const HireUsButtonHeader = () => {
    const { t } = useTranslation();

    return (
        <button className={styles.hireUsButton}>
            <span className={styles.hireUsButtonText}>
                {t('hireUsButton.hireUsText')}
            </span>
        </button>
    );
};
