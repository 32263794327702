import { useEffect, useState } from 'react';
import { IS_TABLET } from 'constants/responsive';
import styles from 'pages/mainPage/mainPage.module.css';

export const MainPage = () => {
    const [shouldShowContent, setShouldShowContent] = useState(false);

    useEffect(() => {
        const handleSplineLoad = () => {
            setShouldShowContent(true);

            const elements = document.querySelectorAll('.slideRight-on-click');
            elements.forEach(element => {
                if (!element.classList.contains(styles.slideRight)) {
                    element.classList.add(styles.slideRight);
                }
            });

            const elementsLeft = document.querySelectorAll(
                '.slideLeft-on-click'
            );
            elementsLeft.forEach(element => {
                if (!element.classList.contains(styles.slideLeft)) {
                    element.classList.add(styles.slideLeft);
                }
            });
        };

        window.addEventListener('load', handleSplineLoad);

        return () => {
            window.removeEventListener('load', handleSplineLoad);
        };
    }, []);

    return (
        <div className={styles.background}>
            {!shouldShowContent && (
                <div className={styles.loading}>Loading...</div>
            )}
            {IS_TABLET ? (
                <div className={styles.loading}>
                    Please open on laptop for preview.
                </div>
            ) : (
                <div className={styles.container}>
                    <video
                        autoPlay={true}
                        loop={true}
                        muted={true}
                        width="1280px"
                        height="100%"
                        playsInline={true}
                    >
                        <source
                            src="https://velvety-general.s3.eu-north-1.amazonaws.com/3dSpline.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>

                    <div className={styles.innerContent}>
                        <div className={styles.header}>
                            <div className={styles.header__company}>
                                <h1>Velvety studio</h1>
                                <div className={styles.header__teamSwitcher}>
                                    <div className={styles.switcher__text}>
                                        Dark
                                    </div>
                                    <div className={styles.switcher__button}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 466.044 466.044"
                                            fill="#000000"
                                        >
                                            <g>
                                                <path
                                                    style={{ fill: '#ffffff' }}
                                                    d="M280.631,466.044c-128.488,0-233.022-104.525-233.022-233.022C47.609,104.533,152.143,0,280.631,0
                                          c41.228,0,81.798,10.974,117.336,31.718l20.468,11.957l-21.622,9.697c-74.945,33.62-123.359,108.354-123.359,190.396
                                          c0,73.848,39.692,142.917,103.607,180.243l20.46,11.949l-21.614,9.697C345.765,459.183,313.706,466.044,280.631,466.044z
                                          M280.631,24.394c-115.036,0-208.636,93.592-208.636,208.636s93.592,208.636,208.636,208.636c21.858,0,43.212-3.341,63.752-9.966
                                          c-59.339-43.61-95.324-113.589-95.324-187.916c0-83.448,44.837-160.117,116.052-201.507
                                          C338.612,30.523,309.813,24.394,280.631,24.394z"
                                                ></path>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.header__decoration}>
                                <svg
                                    viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginLeft: '-1px' }}
                                >
                                    <path
                                        d="M 0 0 L 40 0 A 40 40 0 0 0 0 40 Z"
                                        fill="black"
                                    />
                                </svg>

                                <svg
                                    viewBox="0 0 40 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={styles.decorationEnd}
                                >
                                    <path
                                        d="M 0 0 L 40 0 A 40 40 0 0 0 0 40 Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                            <div className={styles.header__signUpActions}>
                                <div className={styles.header__loginSwitcher}>
                                    <button
                                        className={styles.loginSwitcher__text}
                                    >
                                        Log In
                                    </button>
                                    <button
                                        className={styles.loginSwitcher__button}
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.mainText} slideRight-on-click`}>
                        <h2>
                            Revolutionize
                            <br />
                            video content
                            <br />
                            with AI{' '}
                        </h2>
                        <span>
                            Stunning 3D animations that
                            <br />
                            bring your projects to life,
                            <br /> making them engaging
                            <br /> in every detail.
                        </span>

                        <div className={styles.swipeBox}>
                            <button className={styles.swipeBox__button}>
                                <svg
                                    fill="#000000"
                                    height="20px"
                                    width="20px"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 330 330"
                                    xmlSpace="preserve"
                                >
                                    <g
                                        id="SVGRepo_bgCarrier"
                                        strokeWidth="0"
                                    ></g>
                                    <g
                                        id="SVGRepo_tracerCarrier"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            id="XMLID_222_"
                                            d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"
                                        ></path>
                                    </g>
                                </svg>
                            </button>
                            <span>Swipe to get started</span>
                        </div>
                    </div>

                    <div className={`${styles.infoBlocks} slideLeft-on-click`}>
                        <div className={styles.infoBlocks__info}>
                            <h3>Who we are ?</h3>
                            <div>
                                At Velvetyweb, we craft exceptional web
                                solutions, creating dynamic websites and 3D
                                animations that bring your brand to life.
                            </div>
                            <button className={styles.infoBlocks__button}>
                                Join us
                            </button>
                        </div>
                        <div className={styles.infoBlocks__info}>
                            <h3>Contact us</h3>
                            <div>
                                Reach out to Velvetyweb for innovative web
                                solutions and stunning 3D animations. Let&apos;s
                                make magic happen!
                            </div>
                            <button className={styles.infoBlocks__button}>
                                Support
                            </button>
                        </div>
                    </div>

                    <div className={styles.bottom}>
                        <div className={styles.bottom__decorationLeft}>
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M 0 0 L 40 0 A 40 40 0 0 0 0 40 Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                        <div className={styles.bottom__action}>
                            <button className={styles.bottom__actionBtn}>
                                Develop Your Brand
                            </button>
                        </div>
                        <div className={styles.bottom__decorationLeft}>
                            <svg
                                className={styles.bottom__decorationLeft_right}
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M 0 0 L 40 0 A 40 40 0 0 0 0 40 Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
