import React from 'react';
import { Element } from 'react-scroll';
import styles from 'sections/contact/contact.module.css';
import { AnimatedPart } from 'components/animatedPart/AnimatedPart';
import { ContactFormLazy } from 'components/contactForm/ContactFormLazy';

export const Contact = () => {
    return (
        <section id={styles.sectionContact}>
            <Element name="contact" className={styles.sectionContainer}>
                <AnimatedPart className={styles.animatedForm}>
                    <ContactFormLazy />
                </AnimatedPart>
            </Element>
        </section>
    );
};
