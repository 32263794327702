import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EN_LANG, RU_LANG, UA_LANG } from 'components/langSwitcher/constants';
import { convertToStringOrNull } from 'utils/dataTypes.utils';
import styles from 'components/langSwitcher/langSwitcher.module.css';

export const LangSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const languageOptions = [EN_LANG, UA_LANG, RU_LANG];

    const [selectedLanguage, setSelectedLanguage] = useState(
        i18n.resolvedLanguage || languageOptions[0]
    );

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option: string) => {
        setSelectedLanguage(option);
        i18n.changeLanguage(option);
        setIsOpen(false);
    };

    const arrowStyles = convertToStringOrNull(isOpen, styles.open);
    const activeBtnStyles = (option: string) =>
        convertToStringOrNull(selectedLanguage === option, styles.open);

    return (
        <div className={styles.languageSwitcher}>
            <div className={styles.switcher} ref={dropdownRef}>
                <div
                    className={`${styles.switcherBtn} ${styles.active}`}
                    onClick={handleToggleDropdown}
                >
                    <span>{selectedLanguage}</span>

                    <div className={`${styles.arrow} ${arrowStyles}`}></div>
                </div>
                {isOpen && (
                    <div className={styles.switcher__options}>
                        {languageOptions.map(
                            option =>
                                option !== selectedLanguage && (
                                    <div
                                        key={option}
                                        className={`${styles.switcherBtn} ${activeBtnStyles(option)}`}
                                        onClick={() =>
                                            handleOptionClick(option)
                                        }
                                    >
                                        {option}
                                    </div>
                                )
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
