import styles from 'components/header/header.module.css';
import { Link } from 'react-scroll';

import { Menu } from 'components/menu/Menu';
import { LangSwitcher } from 'components/langSwitcher/LangSwitcher';
import { Logo } from 'components/vectors/Logo';
import { BurgerMenu } from 'components/burgerMenu/BurgerMenu';
import { HireUsButtonHeader } from 'components/hireUsButtonHeader/hireUsButtonHeader';

export const Header = () => {
    return (
        <header className={styles.header}>
            <Link
                className={styles.logo}
                to="home"
                smooth={true}
                duration={800}
                spy={true}
                offset={-87}
            >
                <Logo />
            </Link>
            <div className={styles.navigation}>
                <div className={styles.visibilityWrapper}>
                    <Menu />
                </div>
            </div>
            <div className={styles.header__actions}>
                <LangSwitcher />

                <Link
                    className={styles.visibilityWrapper}
                    to="contact"
                    smooth={true}
                    duration={800}
                    spy={true}
                    offset={-87}
                >
                    <HireUsButtonHeader />
                </Link>
                <BurgerMenu />
            </div>
        </header>
    );
};
