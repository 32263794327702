import React from 'react';

export const ContactUsTelegramIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title id="telegramWhiteIconT">Telegram icon</title>
            <desc id="telegramWhiteIconD">
                A vector illustration of a telegram icon
            </desc>
            <rect
                x=".5"
                y=".5"
                width="31"
                height="31"
                rx="3.5"
                stroke="#FDFCFC"
            />
            <path
                d="M16.5 5.259a10.5 10.5 0 1 0 0 21 10.5 10.5 0 0 0 0-21Zm5.158 7.192-1.72 8.125c-.13.577-.472.709-.957.446l-2.625-1.942-1.313 1.22a.671.671 0 0 1-.525.263l.184-2.625 4.856-4.397c.223-.184 0-.289-.315-.105l-5.959 3.74-2.625-.813c-.564-.17-.577-.565.118-.827l10.12-3.938c.499-.144.919.145.761.853Z"
                fill="#FDFCFC"
            />
        </svg>
    );
};
