import styles from 'components/footer/footer.module.css';

export const PhoneNumbers = () => {
    return (
        <div className={styles.phoneNumbers}>
            <a
                href="tel:+971 (50) 370 57 30"
                className={styles.footer__contactUsPhoneNumber}
            >
                +971 (50) 370 57 30
            </a>
            <a
                href="tel:+380 (73) 239 58 81"
                className={styles.footer__contactUsPhoneNumber}
            >
                +380 (73) 239 58 81
            </a>
        </div>
    );
};
